import React, { useContext } from "react";
import UpdateProfileInformation from "./UpdateProfileInformation";
import PictureIdentification from "./PictureIdentification";
import AllTransactions from "./AllTransactions";
import AllLicences from "./AllLicences";
import { FLISContext } from "../context/FLISContext";
import AllDraftApplications from "./AllDraftApplications";
import ReturnedForCorrections from "./ReturnedForCorrections";
import { DataProtection } from "./DataProtection";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Info = ({ title, value }) => {
  return (
    <div>
      <b>{title} - </b> {value}
    </div>
  );
};

function Profile(props) {
 

  const { currentEntity } = useContext(FLISContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const active = queryParams.get('active') || 'licence-permit-tab';

  console.log(active);



  return (
    <div className="ml-4 mr-4">
      <div
        style={{
          backgroundColor: "aliceblue",
          padding: "20px",
          marginBottom: "10px",
        }}
        className="d-flex-wrap flex-row justify-content-between"
      >
        <div className="d-flex-wrap flex-column">
          <Info
            title={"NFA Registration #"}
            value={currentEntity?.nfa_registration_number}
          />

          <Info
            title={"Type of Applicant"}
            value={currentEntity?.applicant_type}
          />

          <Info
            title={"Category of Applicant"}
            value={currentEntity?.category_of_applicant}
          />

          <Info title={"Nationality"} value={currentEntity?.domicile} />

          {currentEntity?.applicant_type === "Individual" && (
            <Info title={"Gender"} value={currentEntity?.gender} />
          )}

          {currentEntity?.applicant_type === "Company" && (
            <Info title={"Website"} value={currentEntity?.website} />
          )}

          <Info
            title={"Country of Origin"}
            value={currentEntity?.country_of_origin}
          />
        </div>

        <div className="d-flex-wrap flex-column align-items-center">
          <div style={{ fontSize: "24px", fontWeight: 700 }}>
            {currentEntity?.applicant_type === "Company" &&
              currentEntity?.company_name}

            {currentEntity?.applicant_type === "Individual" &&
              `${currentEntity?.first_name_individual} ${currentEntity?.middle_name_individual} ${currentEntity?.last_name_individual}`}
          </div>

          <div>
            <b>Entity Address - </b> {currentEntity?.address}
          </div>
        </div>

        <div>
          <Info
            title={"TRN"}
            value={currentEntity?.taxation_registration_number}
          />
          <Info title={"Entity Number"} value={currentEntity?.entity_number} />
          <Info title={"Cell Phone"} value={currentEntity?.cell_phone} />
          <Info
            title={"Work / Home Phone"}
            value={currentEntity?.work_home_phone}
          />
          {currentEntity?.applicant_type === "Individual" && (
            <Info
              title={"Date of Birth"}
              value={currentEntity?.date_of_birth_individual?.substring(0, 10)}
            />
          )}

          {currentEntity?.applicant_type === "Company" && (
            <Info
              title={"Date of Registration/Incorporation"}
              value={currentEntity?.date_of_registration}
            />
          )}
        </div>
      </div>

      <div>
        <ul class="mb-2 nav nav-tabs profile-tab" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
               className={`nav-link ${active === 'home-tab' ? 'active' : ''}`}
              id="home-tab"
              data-toggle="tab"
              data-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Profile Information
            </button>
          </li>
          {currentEntity?.applicant_type === "Individual" && (
            <li class="nav-item" role="presentation">
              <button
                 className={`nav-link ${active === 'profile-tab' ? 'active' : ''}`}
                id="profile-tab"
                data-toggle="tab"
                data-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Picture Identification
              </button>
            </li>
          )}
          <li class="nav-item" role="presentation">
            <button
              className={`nav-link ${active === 'transactions-tab' ? 'active' : ''}`}
              id="transactions-tab"
              data-toggle="tab"
              data-target="#transactions"
              type="button"
              role="tab"
              aria-controls="transactions"
              aria-selected="false"
            >
              View All Applications
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button
              className={`nav-link ${active === 'licence-permit-tab' ? 'active' : ''}`}
              id="licence-permit-tab"
              data-toggle="tab"
              data-target="#licence-permit"
              type="button"
              role="tab"
              aria-controls="licence-permit"
              aria-selected="false"
            >
              Licences/Permits Granted
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button
              className={`nav-link ${active === 'draft-applications-tab' ? 'active' : ''}`}
              id="draft-applications-tab"
              data-toggle="tab"
              data-target="#draft-applications"
              type="button"
              role="tab"
              aria-controls="draft-applications"
              aria-selected="false"
            >
              Draft Applications
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button
              className={`nav-link ${active === 'corrections-applications-tab' ? 'active' : ''}`}
              id="corrections-applications-tab"
              data-toggle="tab"
              data-target="#corrections-applications"
              type="button"
              role="tab"
              aria-controls="corrections-applications"
              aria-selected="false"
            >
              Returned For Corrections
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button
              className={`nav-link ${active === 'data-protection-tab' ? 'active' : ''}`}
              id="data-protection-tab"
              data-toggle="tab"
              data-target="#data-protection"
              type="button"
              role="tab"
              aria-controls="data-protection"
              aria-selected="false"
            >
              Data Protection
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            className={`tab-pane fade ${active === 'home-tab' ? 'show active' : ''}`}
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {currentEntity?.id && (
              <UpdateProfileInformation entity={currentEntity} />
            )}
          </div>
          <div
           className={`tab-pane fade ${active === 'profile-tab' ? 'show active' : ''}`}
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {currentEntity?.id && <PictureIdentification />}
          </div>
          <div
            className={`tab-pane fade ${active === 'transactions-tab' ? 'show active' : ''}`}
            id="transactions"
            role="tabpanel"
            aria-labelledby="transactions-tab"
          >
            <AllTransactions />
          </div>

          <div
            className={`tab-pane fade ${active === 'licence-permit-tab' ? 'show active' : ''}`}
            id="licence-permit"
            role="tabpanel"
            aria-labelledby="licence-permit-tab"
          >
            <AllLicences />
          </div>

          <div
            className={`tab-pane fade ${active === 'draft-applications-tab' ? 'show active' : ''}`}
            id="draft-applications"
            role="tabpanel"
            aria-labelledby="draft-applications-tab"
          >
            <AllDraftApplications />
          </div>

          <div
            className={`tab-pane fade ${active === 'corrections-applications-tab' ? 'show active' : ''}`}
            id="corrections-applications"
            role="tabpanel"
            aria-labelledby="corrections-applications-tab"
          >
            <ReturnedForCorrections />
          </div>

          <div
            className={`tab-pane fade ${active === 'data-protection-tab' ? 'show active' : ''}`}
            id="data-protection"
            role="tabpanel"
            aria-labelledby="data-protection-tab">
            <DataProtection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
