import React, { useState, useRef } from 'react';
import './multi-select.css'; // Import the CSS file

const MultiSelect = ({
    parentClass = "individual-field",
    fieldClassName = "form-control",
  register,
  handleSubmit,
  formState: { errors },
  setValue,
  name,
  label,
  options,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleCheckboxChange = (value) => {
    const newSelectedValues = [...selectedValues];
    const index = newSelectedValues.indexOf(value);
    if (index === -1) {
      newSelectedValues.push(value);
    } else {
      newSelectedValues.splice(index, 1);
    }
    setSelectedValues(newSelectedValues);
    setValue(name, newSelectedValues); // Update form value
  };

  const handleApplyClick = () => {
    // Handle form submission or other actions here
    console.log('Selected values:', selectedValues);
    setIsDropdownOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSelectedValues([]);
      setValue(name, []); // Clear form value
    }
  };

  const renderSelectedTags = () => {
    return selectedValues.map((value, index) => (
      <span key={index} className="tag">
        {value}
        <span className="close-icon" onClick={() => handleCheckboxChange(value)}>
          &times;
        </span>
      </span>
    ));
  };

  return (
    <div className={`multi-select-container ${parentClass}`}>
      <label htmlFor={name}>{label}</label>
      <div>
      <input
      className={fieldClassName}
        type="text"
        placeholder="Select options"
        {...register(name)}
        onClick={() => setIsDropdownOpen(true)}
      />
      </div>

      
      <div className="selected-tags">
        {/* {renderSelectedTags()} */}
      </div>
      <div className="dropdown" ref={dropdownRef} style={{ display: isDropdownOpen ? 'block' : 'none' }}>
        {/* Render dropdown options with checkboxes here */}
        <ul>
          {options.map((option) => (
            <li key={option.value}>
                <label className="checkbox-container">

                <input
                type="checkbox"
                className="checkbox-container"
                id={option.value}
                value={option.value}
                checked={selectedValues.includes(option.value)}
                onChange={(e) => handleCheckboxChange(e.target.value)}
              />
              <span className="checkbox-checkmark"> </span>
              {option.label}
                </label>
            
              {/* <label htmlFor={option.value}>{option.label}</label> */}
            </li>
          ))}
        </ul>
        <button className='btn btn-primary' onClick={handleApplyClick}>Apply</button>
      </div>
    </div>
  );
};

export default MultiSelect;