import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import "../../../assets/styles/form.css";
import HorizontalLine from "../../components/HorLine";
import { ROUTES, PARISH_LIST, ALL_DROPDOWNS } from "../../config/index";
import SimpleNavigator from "../../components/SimpleNavigator";
import { postData } from "../../services/service-call";
import { getCurrentTransaction } from "../../redux/actions/current-transaction";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import { alertService } from "../../_services";
import _, { pick } from "lodash";
import { SignaturePad } from "../../components/SignatureCanvas";

import {
  typeOfTransactions,
  Declarations,
  FISHING_VESSEL_LICENCE,
  MainGearOwnedBy,
  fishingLocationList,
  Vessel_Fishing_GearType,
  Break,
  COMMERCIAL,
  VesselClasses,
  fishingAreaLocationsFishingVessel,
  NEW_LICENCE,
  fishingAreaLocations,
  regionParishPort,
  regionParishList,
  BASE_LOCATION_PORT,
  baseLocations,
  BASE_LOCATION_INLAND,
  InlandList,
  regionListForInland,
  VesselOwnerships,
  FuelTypes,
} from "../../components/DataSource";
import ImageUpload from "../../components/ImageUpload";
import { FLISContext } from "../../context/FLISContext";
import {
  FormHookCheckbox,
  FormHookFileUpload,
  FormHookInput,
  FormHookSelect,
  handleKeyDown,
} from "../../components/FormHook/ReactFormHookFields";
import { FormHookFileUploadHandler } from "../../components/FormHook/FormHookDocument";
import { GearInformation } from "./GearInformation";
import GeneralDeclarations from "../GeneralDeclaration";
import { fillInAllTheFields, getPreviewHeading, saveAndContinueLaterText, scrollToTop } from "../../utils/utils";
import SimpleDeclaration from "../SimpleDeclaration";
import { EngineDetails } from "../../components/EngineDetails";


export const ApplicationForFishingVessel = (props) => {
  const { selectedMap, setSelectedMap } = useContext(FLISContext);
  const history = useHistory();
  const buttonSubmitRef = useRef();


  const transactionDetails = useSelector((state) => {
    return state.currentTransaction;
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: selectedMap,
    mode: 'all'
  });

  React.useImperativeHandle(props.reference, () => {
    return {
      ...(props?.reference?.current || {}),
      trigger: trigger,
    };
  });

  const { errors } = formState;


  const knownEngineNumber = watch('fishing_vessel_details.engine_serial_number_available');
  
  useEffect(() => {
    if (transactionDetails.temp_trans_id) {
      // dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
    }
  }, [transactionDetails.temp_trans_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let appType = useSelector((state) => {
    return state?.currentTransaction?.appType;
  });
  const onSubmit = async (data) => {
    if (selectedForm === 1) {
      scrollToTop();
      setSelectedForm(2);
      return;
    }

    data.temp_trans_id = props.temp_trans_id;
    history.push(props.next);
  };

  const nextAction = async () => {
    const isValid = await trigger();
    const data = getValues();

    if(selectedForm === 2 && !data?.applicant_signature  && !data?.signature_draw_path){
      alertService.warn('Fill all the mandatory fields');
      return; 
    }
    

    if(isValid){
      setSelectedMap(data)
    } else {
      alertService.warn(fillInAllTheFields);
    }
    buttonSubmitRef.current.click();
  };

  const previousAction = () => {
    const data = getValues();
    setSelectedMap(data)
    if (selectedForm === 1) {
      history.push(props.previous);
      return;
    } else if (selectedForm === 2) {
      setSelectedForm(1);
    }
    scrollToTop();
  };

  const saveAndContinueLater = async () => {
    const currentData = getValues();
    currentData.temp_trans_id = props.temp_trans_id;
    
    const result = await postData({
      url: `${ROUTES.saveApplication}?app_type=${appType}`,
      body: {
        ...selectedMap,
        temp_trans_id: props.temp_trans_id,
        app_type: appType,
        launchCase: false,
      },
    });

    if (result.statusCode === 200) {
      alertService.success("Application saved successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });

      history.replace("/application-types");
    } else {
      alertService.error("Error occured while saving application", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    }
  };

  const saveYourStore = () => {
    const data = getValues();
    data.temp_trans_id = props.temp_trans_id;
    // localStorage.setItem("ftc_complaints", JSON.stringify(data));
  };

  const [selectedForm, setSelectedForm] = useState(1);

  return (
    <div className="container-fluid " ref={componentRef}>
      <div className="head-text">
        {props.isPreview
          ? getPreviewHeading(appType, props.isFormPDF)
          : selectedForm === 1
          ? "Application Form"
          : "Declaration of Knowledge - Form"}
      </div>

      <div className="form-container">
        <fieldset disabled={props.isDisabled || props.isPreview}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            // onChange={() => saveYourStore()}
            ref={props.reference}
          >
            {selectedForm === 1 && (
              <>
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Applicant Information</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookInput
                      register={register}
                      error={errors?.nfa_registration_number?.message}
                      label="NFA Registration Number"
                      regFieldName="nfa_registration_number"
                      placeholder="NFA Registration Number"
                      isRequired={false}
                      type="text"
                      isDisabled={true}
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.fishing_vessel_details
                          ?.vessel_identification_number?.message
                      }
                      label="Vessel Identification / Decal Number:"
                      regFieldName="fishing_vessel_details.vessel_identification_number"
                      placeholder="Vessel Identification / Decal Number:"
                      type="text"
                      isRequired={
                        selectedMap.type_of_transaction === NEW_LICENCE
                          ? false
                          : "This is required"
                      }
                      isDisabled={true}
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.fishing_vessel_details?.name_of_vessel?.message
                      }
                      label="Name of Vessel:"
                      regFieldName="fishing_vessel_details.name_of_vessel"
                      placeholder="NFA Registration Number"
                      type="text"
                      isDisabled={true}
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.fishing_vessel_details?.maj_registration_number
                          ?.message
                      }
                      label="MAJ Registration Number:"
                      regFieldName="fishing_vessel_details.maj_registration_number"
                      placeholder="MAJ Registration #"
                      type="text"
                      isDisabled={true}
                    />

                    <Break />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.fishing_vessel_details?.home_base_port?.message
                      }
                      label="Home / Base Port:"
                      regFieldName="fishing_vessel_details.home_base_port"
                      placeholder="Home / Base Port"
                      type="text"
                      isDisabled={true}
                    />

                    <FormHookSelect
                      // label="Vessel has Valid Seagoing Certificate"
                      label="Vessel has Small Vessel Safety Certificate"
                      register={register}
                      regFieldName="fishing_vessel_details.has_valid_seagoing_certificate"
                      options={["Yes", "No"]}
                      error={
                        errors?.fishing_vessel_details
                          ?.has_valid_seagoing_certificate?.message
                      }
                      parentClass="individual-field"
                      isDisabled={true}
                    />

                    <FormHookSelect
                      label="Vessel Ownership"
                      register={register}
                      regFieldName="fishing_vessel_details.vessel_ownership"
                      options={VesselOwnerships}
                      error={
                        errors?.fishing_vessel_details?.vessel_ownership
                          ?.message
                      }
                      isDisabled={true}
                    />

                    <FormHookSelect
                      label="Transaction Required"
                      register={register}
                      regFieldName="type_of_transaction"
                      options={typeOfTransactions[FISHING_VESSEL_LICENCE]}
                      error={errors?.type_of_transaction?.message}
                      isDisabled={true}
                    />

                    <Break />

                    <FormHookInput
                      label="Type of Licence"
                      register={register}
                      regFieldName="licence_type"
                      error={errors?.licence_type?.message}
                      isDisabled={true}
                      isRequired={true}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Vessel Features</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    {/* <FormHookInput
                      label="Vessel Class:"
                      register={register}
                      regFieldName="fishing_vessel_details.vessel_class"
                      options={[
                        "Non-Decked (Canoe)",
                        "Partial Decked",
                        "Full Descked (Trawler)",
                        "Yacht / Sail",
                      ]}
                      error={
                        errors?.fishing_vessel_details?.vessel_class?.message
                      }
                    /> */}

                    <Break />

                    <FormHookCheckbox
                      label="Hull Material:"
                      register={register}
                      regFieldName="fishing_vessel_details.hull_material"
                      options={["Fiberglass", "Steel", "Wood", "Other"]}
                      error={
                        errors?.fishing_vessel_details?.hull_material?.message
                      }
                      isMulti={true}
                    />

                    <Break />
                    <FormHookInput
                      register={register}
                      error={
                        errors?.fishing_vessel_details?.hull_color?.message
                      }
                      label="Hull Colour:"
                      regFieldName="fishing_vessel_details.hull_color"
                      placeholder="Hull Colour"
                      type="text"
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.fishing_vessel_details?.length_in_meters
                          ?.message
                      }
                      label="Length (Metres)"
                      regFieldName="fishing_vessel_details.length_in_meters"
                      placeholder="Length (Metres)"
                      type="number"
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.fishing_vessel_details?.beam_in_meters?.message
                      }
                      label="Beam (Metres)"
                      regFieldName="fishing_vessel_details.beam_in_meters"
                      placeholder="Beam (Metres)"
                      type="number"
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.fishing_vessel_details?.tonnage_mt?.message
                      }
                      label="Tonnage (Meters)"
                      regFieldName="fishing_vessel_details.tonnage_mt"
                      placeholder="Tonnage (Meters)"
                      type="number"
                    />

                    <Break />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.fishing_vessel_details?.max_crew_size?.message
                      }
                      label="Max Crew Size"
                      regFieldName="fishing_vessel_details.max_crew_size"
                      placeholder="Max Crew Size"
                      type="number"
                    />

                    <FormHookCheckbox
                      label="Range:"
                      error={errors?.fishing_vessel_details?.range?.message}
                      register={register}
                      regFieldName="fishing_vessel_details.range"
                      options={["Coastal", "Offshore"]}
                      isMulti={true}
                    />

                    <Break />
                    <FormHookCheckbox
                      label="Fuel Type:"
                      error={errors?.fishing_vessel_details?.fuel_type?.message}
                      register={register}
                      regFieldName="fishing_vessel_details.fuel_type"
                      options={FuelTypes}
                      isMulti={false}
                    />

                    <div>
                      <label className="individual-field-margin mt-2">
                        Engine Details:
                      </label>

                      <EngineDetails
                        register={register}
                        formState={formState}
                        watch={watch}
                        control={control}
                        onSubmit={onSubmit}
                        setValue={setValue}
                        isDisabled={true}
                      ></EngineDetails>
                    </div>

                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Gear Information</div>
                  <HorizontalLine></HorizontalLine>

                  <GearInformation
                    formState={formState}
                    register={register}
                    onSubmit={onSubmit}
                    setValue={setValue}
                    values={getValues}
                    watch={watch}
                    source={Vessel_Fishing_GearType}
                    disabled={true}
                  />

                  {/* <table className="flex-grow-1 table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Gear Type</th>
                      <th>No. of Gear</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Vessel_Fishing_GearType.map((gear) => {
                      return (
                        <tr>
                          <td  className="non-functional-element">
                            <label key={gear} className="checkbox-container">
                              {gear}
                              <input
                               
                                onKeyDown={handleKeyDown}
                                type="checkbox" 
                                {...register(
                                  `gear_information.${gear}.isChecked`,
                                  {
                                    required: false,
                                  }
                                )}
                              />
                              <span className="checkbox-checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <FormHookInput
                              hideLabel={true}
                              parentClass="individual-field non-functional-element"
                              register={register}
                              error={
                                errors?.gear_information?.[gear]
                                  ?.number_of_gears?.message
                              }
                              regFieldName={`gear_information.${gear}.number_of_gears`}
                              placeholder=""
                              type="number"
                              isDisabled={false}
                              isRequired={false}
                            />
                          </td>
                        </tr>
                      );
                    })}

                  </tbody>
                </table> */}

                  <FormHookCheckbox
                    label="Main Gear Owned By"
                    register={register}
                    regFieldName="main_gear_owned_by"
                    options={MainGearOwnedBy}
                    error={errors?.main_gear_owned_by?.message}
                    isDisabled={true}
                  />

                  <FormHookCheckbox
                    label="Port Or Inland"
                    options={baseLocations}
                    register={register}
                    error={errors?.port_or_inland?.message}
                    regFieldName={`port_or_inland`}
                    isDisabled={true}
                    isMulti={false}
                  />

                  <Break />

                  {selectedMap.port_or_inland === BASE_LOCATION_PORT && (
                    <div>
                      <FormHookCheckbox
                        label="Region/Parish"
                        options={regionParishList}
                        register={register}
                        error={errors?.region?.message}
                        regFieldName={`region`}
                        isDisabled={true}
                        isMulti={false}
                      />

                      <FormHookCheckbox
                        label="Port"
                        options={regionParishPort?.[selectedMap.region]}
                        register={register}
                        error={errors?.port?.message}
                        regFieldName={`port`}
                        isDisabled={true}
                        isMulti={false}
                      />

                      <FormHookCheckbox
                        label="Fishing Area"
                        options={fishingAreaLocationsFishingVessel ?? []}
                        register={register}
                        error={errors?.fishing_area?.message}
                        regFieldName={`fishing_area`}
                        isDisabled={true}
                      />
                    </div>
                  )}

                  {selectedMap.port_or_inland === BASE_LOCATION_INLAND && (
                    <div>

                      
                      <FormHookCheckbox
                        label="Region/Parish"
                        options={regionListForInland}
                        register={register}
                        error={errors?.region?.message}
                        regFieldName={`region`}
                        isDisabled={true}
                        isMulti={false}
                      />

                      <Break />

                      <FormHookCheckbox
                        label="Inland"
                        options={InlandList}
                        register={register}
                        error={errors?.inland?.message}
                        regFieldName={`inland`}
                        isDisabled={true}
                        isMulti={false}
                      />
                    </div>
                  )}
                </div>

                <SimpleDeclaration register={register} errors={errors} />

                {/* <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Declaration</div>
                  <HorizontalLine></HorizontalLine>

                  <div>
                    I / We declare that the information given is true and
                    correct to the best of my / our knowledge and belief.
                  </div>

                  <div className="error-message">
                    {!watch("applicant_signature") &&
                      !watch("signature_draw_path") && (
                        <span>Atleast one field is required from below</span>
                      )}
                  </div>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookFileUploadHandler
                      error={errors?.applicant_signature?.message}
                      register={register}
                      watch={watch}
                      label="Upload Applicant's Signature"
                      accept=".jpg, .jpeg, .png"
                      regFieldName="applicant_signature"
                      fieldClassName="field-block-control"
                      isPreviewable={true}
                      isRequired={false}
                      removeFile={(id) => setValue(id, null)}
                      setValue={setValue}
                    ></FormHookFileUploadHandler>

                    <SignaturePad
                      isDisabled={props.isDisabled || props.isPreview}
                      currentValue={watch("signature_draw_path")}
                      setSignatureValue={(id, base64) => setValue(id, base64)}
                      id="signature_draw_path"
                      label="Or Draw Your Signature Below"
                    />
                  </div>
                </div> */}
              </>
            )}

            {(selectedForm === 2 || props.isPreview) && (
              <GeneralDeclarations
                register={register}
                errors={errors}
                watch={watch}
                isDisabled={props.isDisabled || props.isPreview}
                setValue={setValue}
              ></GeneralDeclarations>
            )}

            <div className="d-flex flex-row-reverse">
              <div className="ml-2">
                <button
                  hidden={true}
                  ref={buttonSubmitRef}
                  className="btn btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </fieldset>
        <div hidden={props.isDisabled || props.isPreview}>
          <SimpleNavigator
            next
            middle
            middleValue={saveAndContinueLaterText}
            middleAction={saveAndContinueLater}
            nextAction={nextAction}
            previousAction={previousAction}
            previous
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    temp_trans_id: state.currentTransaction.temp_trans_id,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationForFishingVessel);
