import React, { useState } from "react";
import {
  FormHookInput,
  FormHookSelect,
} from "../components/FormHook/ReactFormHookFields";
import BoxChoiceContainer from "../components/BoxChoiceContainer";
import { useForm } from "react-hook-form";
import {
  emailPattern,
  formatPhoneNumber,
  telephonePattern,
} from "../components/DataSource";
import { emailNotValid, telephoneNotValid } from "../utils/messages";
import { alertService } from "../_services";
import { postData } from "../services/service-call";
import { ROUTES } from "../config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ForgotPassword = () => {
  const APPLICANT = "Applicant";
  const SUPPORTING_OFFICER = "Supporting Officer";
  const EMAIL = "Email";
  const TELEPHONE_NUMBER = "Telephone Number";

  const [selectionIndex, setSelectionIndex] = useState(1);
  const [resetMethod, setResetMethod] = useState("");
  const [resetFor, setResetFor] = useState(APPLICANT);
  const [userType, setUserType] = useState("");

  const [selectedMap, setSelectedMap] = useState({});

  function isEmailAddress(str) {
    var pattern = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    return pattern.test(str);
  }

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    control,
  } = useForm({
    defaultValues: {},
    mode: "all",
  });

  const { errors } = formState;

  const history = useHistory();

  return (
    <div className="regitration-container">
      <div style={{ paddingBottom: "0.5rem" }} className="login-form-container">
        <div
          style={{
            textAlign: "center",
            fontSize: "24px",
            background: "green",
            color: "white",
            fontWeight: 700,
          }}
        >
          Reset Password
        </div>
        {selectionIndex === 1 && (
          <BoxChoiceContainer
            listingHeading={"Select your user type"}
            listingOptions={[APPLICANT, SUPPORTING_OFFICER]}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={1}
            prop_key={"user_type"}
            onClick={(value, i) => {
              setUserType(value);
              if (value === APPLICANT) {
                setSelectionIndex(selectionIndex + 2);
              } else if (value === SUPPORTING_OFFICER) {
                setSelectionIndex(selectionIndex + 1);
              }
            }}
            listingStyle="justify-content-center"
            listingDescriptions={[]}
          ></BoxChoiceContainer>
        )}

        {selectionIndex === 2 && (
          <div>
            <BoxChoiceContainer
              listingHeading={"You are resetting password for? Select option below "}
              listingOptions={["Applicant", "Supporting Officer"]}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
              selectionCategory={1}
              prop_key={"reset_for"}
              onClick={(value, i) => {
                setResetFor(value);

                if (
                  userType === SUPPORTING_OFFICER &&
                  value === SUPPORTING_OFFICER
                ) {
                  setResetMethod("Email");
                  setSelectionIndex(selectionIndex + 2);
                } else {
                  setSelectionIndex(selectionIndex + 1);
                }
              }}
              listingStyle="justify-content-center"
              listingDescriptions={[]}
            ></BoxChoiceContainer>

            <div className="mt-4 ml-2 mr-2 d-flex justify-content-between">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  setSelectionIndex(selectionIndex - 1);
                }}
              >
                Back
              </button>
            </div>
          </div>
        )}

        {selectionIndex === 3 && (
          <div>
            <BoxChoiceContainer
              listingHeading={"Reset password using? Select option below"}
              listingOptions={["Email", "Telephone Number"]}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
              selectionCategory={1}
              prop_key={"reset_method"}
              onClick={(value, i) => {
                setResetMethod(value);
                setSelectionIndex(selectionIndex + 1);
              }}
              listingStyle="justify-content-center"
              listingDescriptions={[]}
            ></BoxChoiceContainer>

            <div className="mt-4 ml-2 mr-2 d-flex justify-content-between">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  if (userType === APPLICANT) {
                    setSelectionIndex(selectionIndex - 2);
                  } else if (userType === SUPPORTING_OFFICER) {
                    setSelectionIndex(selectionIndex - 1);
                  }
                }}
              >
                Back
              </button>
            </div>
          </div>
        )}

        {selectionIndex === 4 && resetMethod === EMAIL && (
          <div>
            <div>
              {((resetFor === APPLICANT && userType === APPLICANT) ||
                (resetFor === SUPPORTING_OFFICER &&
                  userType === SUPPORTING_OFFICER)) && (
                <FormHookInput
                  register={register}
                  error={errors?.email?.message}
                  label={`${userType === APPLICANT ? "Enter email address of the applicant used at the time of registration" : "Enter email address of the supporting officer used at the time of registration"}`}
                  regFieldName="email"
                  placeholder="Email"
                  validations={{
                    pattern: {
                      value: emailPattern,
                      message: emailNotValid,
                    },
                  }}
                  fieldClassName="form-control"
                />
              )}

              {userType === SUPPORTING_OFFICER && resetFor === APPLICANT && (
                <div>
                  <FormHookInput
                    register={register}
                    error={errors?.applicant_email?.message}
                    label="Enter applicant email address used at the time of registration"
                    regFieldName="applicant_email"
                    placeholder="Email"
                    validations={{
                      pattern: {
                        value: emailPattern,
                        message: emailNotValid,
                      },
                    }}
                    fieldClassName="form-control"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.supporting_officer_email?.message}
                    label="Enter supporting officer email address used at the time of registration"
                    regFieldName="supporting_officer_email"
                    placeholder="Email"
                    validations={{
                      pattern: {
                        value: emailPattern,
                        message: emailNotValid,
                      },
                    }}
                    fieldClassName="form-control"
                  />
                </div>
              )}
            </div>

            <div className="mt-4 ml-2 mr-2 d-flex justify-content-between">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  if (
                    userType === SUPPORTING_OFFICER &&
                    resetFor === SUPPORTING_OFFICER
                  ) {
                    setSelectionIndex(selectionIndex - 2);
                  } else {
                    setSelectionIndex(selectionIndex - 1);
                  }
                }}
              >
                Back
              </button>

              <button
                style={{ marginLeft: "10px" }}
                onClick={async () => {
                  const data = getValues();
                  const isValid = await trigger();
                  if (!isValid) {
                    return;
                  }
                  const result = await postData({
                    url: ROUTES.resetPassword,
                    body: {
                      ...data,
                      reset_method: resetMethod,
                      user_type: userType,
                      reset_for: resetFor,
                    },
                  });
                  if (result.statusCode === 200) {
                    alertService.success(
                      "Password reset link has been sent to email address",
                      { autoClose: true, keepAfterRouteChange: true }
                    );
                    history.push("/login");
                  } else {
                    alertService.error(result.data?.msg, {
                      autoClose: true,
                      keepAfterRouteChange: true,
                    });
                  }
                }}
                className="btn btn-success"
                type="button"
              >
                Reset Password
              </button>
            </div>
          </div>
        )}

        {selectionIndex === 4 && resetMethod === TELEPHONE_NUMBER && (
          <div>
            <div>
              {((resetFor === APPLICANT && userType === APPLICANT) ||
                (resetFor === SUPPORTING_OFFICER &&
                  userType === SUPPORTING_OFFICER)) && (
                <div>
                  <FormHookInput
                    register={register}
                    error={errors?.telephone_number?.message}
                    label="Enter cell phone number used at the time of registration"
                    regFieldName="telephone_number"
                    placeholder={"Cell Number"}
                    fieldClassName="form-control"
                    validations={{
                      pattern: {
                        value: telephonePattern,
                        message: telephoneNotValid,
                      },
                    }}
                    onChange={formatPhoneNumber}
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.email?.message}
                    label="Enter email address to be used for sending password reset link"
                    regFieldName="email"
                    placeholder="Email"
                    validations={{
                      pattern: {
                        value: emailPattern,
                        message: emailNotValid,
                      },
                    }}
                    fieldClassName="form-control"
                  />

                  <FormHookInput
                    register={register}
                    error={
                      errors?.date_of_birth_individual_or_date_of_registration
                        ?.message
                    }
                    label="Date of Birth (Individual) or Date of Registration (Company) - Security Question"
                    regFieldName="date_of_birth_individual_or_date_of_registration"
                    type="date"
                  />
                </div>
              )}

              {resetFor === APPLICANT && userType === SUPPORTING_OFFICER && (
                <div
                  style={{
                    fontSize: "22px",
                    padding: "5px",
                    maxWidth: "568px",
                    textAlign: "center",
                  }}
                >
                  <b>
                    Please create a ticket - cannot reset password of the
                    applicant using Telephone Number
                  </b>
                </div>
              )}
            </div>

            {resetFor === APPLICANT && userType === SUPPORTING_OFFICER && (
              <div className="mt-4 ml-2 mr-2 d-flex justify-content-between">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    setSelectionIndex(selectionIndex - 1);
                  }}
                >
                  Back
                </button>

                <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => {
                    window.open("https://iriefins.tawk.help/");
                  }}
                >
                  Create Ticket
                </button>
              </div>
            )}
            {!(resetFor === APPLICANT && userType === SUPPORTING_OFFICER) && (
              <div className="mt-4 ml-2 mr-2 d-flex justify-content-between">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    setSelectionIndex(selectionIndex - 1);
                  }}
                >
                  Back
                </button>

                <button
                  style={{ marginLeft: "10px" }}
                  onClick={async () => {
                    const isValid = await trigger();
                    if (!isValid) {
                      return;
                    }
                    const data = getValues();
                    const result = await postData({
                      url: ROUTES.resetPassword,
                      body: {
                        ...data,
                        reset_method: resetMethod,
                        user_type: userType,
                        reset_for: resetFor,
                      },
                    });
                    if (result.statusCode === 200) {
                      alertService.success(
                        "Password reset link has been sent to email address",
                        { autoClose: true, keepAfterRouteChange: true }
                      );
                      history.push("/login");
                    } else {
                      alertService.error(result.data.msg, {
                        autoClose: true,
                        keepAfterRouteChange: true,
                      });
                    }
                  }}
                  className="btn btn-success"
                  type="button"
                >
                  Reset Password
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
