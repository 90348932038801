
const mapCommonCardProperties = {
    "FLIS_IDCardDateofExpiry": "payment_details.fisher_card_id_expiry_date",
    "FLIS_IDCardDateofIssue": "payment_details.fisher_card_id_issue_date"
};



export const icmPropMapping = {

    "APPLICATION_FOR_LICENCE" : {
        "FLIS_NationalityCountryofOrigin": "country_of_origin",
        "FLIS_SubCategory": "commercial_category",
        "FLIS_NameofApplicant": "first_name_individual",
        "FLIS_NameofApplicantMiddle": "middle_name_individual",
        "FLIS_NameofApplicantLast": "last_name_individual",
        "FLIS_NameofApplicantAlias": "alias_individual",
        "FLIS_ApplicantsRoleisFisheriesOther": "other_applicant_role",
        "FLIS_NameofOrganizationCompany": "company_name",
        "FLIS_SpecialTypeofSpecies": "special_type_of_species",
        "FLIS_Address": "address",
        ...mapCommonCardProperties,
    },
    
    "APPLICATION_FOR_AQUACULTURE_FISHER" : {
        "FLIS_NationalityCountryofOrigin": "country_of_origin",
        "FLIS_SubCategory": "commercial_category",
        "FLIS_NameofApplicant": "first_name_individual",
        "FLIS_NameofApplicantMiddle": "middle_name_individual",
        "FLIS_NameofApplicantLast": "last_name_individual",
        "FLIS_NameofApplicantAlias": "alias_individual",
        "FLIS_ApplicantsRoleisFisheriesOther": "other_applicant_role",
        "FLIS_NameofOrganizationCompany": "company_name",
        "FLIS_SpecialTypeofSpecies": "special_type_of_species",
        "FLIS_Address": "address",
        ...mapCommonCardProperties,
    },

    "APPLICATION_FOR_FISHER_WORKER_ID" : {
        "FLIS_NationalityCountryofOrigin": "country_of_origin",
        "FLIS_SubCategory": "commercial_category",
        "FLIS_NameofApplicant": "first_name_individual",
        "FLIS_NameofApplicantMiddle": "middle_name_individual",
        "FLIS_NameofApplicantLast": "last_name_individual",
        "FLIS_NameofApplicantAlias": "alias_individual",
        "FLIS_ApplicantsRoleisFisheriesOther": "other_applicant_role",
        "FLIS_NameofOrganizationCompany": "company_name",
        "FLIS_SpecialTypeofSpecies": "special_type_of_species",
        "FLIS_Address": "address",
        ...mapCommonCardProperties,
    },

    "APPLICATION_FOR_FISHING_VESSEL": {
        "FLIS_Range": "fishing_vessel_details.range",
        "FLIS_RangeN": "fishing_vessel_details.range",
        "FLIS_VesselIdentificationDecal": "fishing_vessel_details.vessel_identification_number",
        "FLIS_VesselClass": "commercial_vessel_class",
        "FLIS_RecreationalFishingCategoryofLicenseVessel": "recreational_licence_type[0]",
        "FLIS_TypeCategoryofLicenseVessel": "licence_type",
        "FLIS_FuelType": "fishing_vessel_details.fuel_type",
        "FLIS_VesselOwnership": "fishing_vessel_details.vessel_ownership",
        "FLIS_CarrierVesselClass": "vessel_type",
        "FLIS_HullColor": "fishing_vessel_details.hull_color",
        "FLIS_BeamMeters": "fishing_vessel_details.beam_in_meters",
        "FLIS_CommercialCategoryofLicenseVessel": "commercial_licence_type",
        "FLIS_MaxCrewSize": "fishing_vessel_details.max_crew_size",
        "FLIS_HomeBasePort": "fishing_vessel_details.home_base_port",
        "FLIS_HullMaterial": "fishing_vessel_details.hull_material",
        
    },
    "APPLICATION_FOR_PERMIT": {
        "FLIS_TotalPrizeMoney": "tournament_information.total_prize_money",
        "FLIS_PermitRequiredfor": "permit_required_for",
        "FLIS_MaximumFishers": "tournament_information.maximum_fishers",
        "FLIS_WeighinEndDate": "tournament_information.weigh_in_end_time",
        "FLIS_HomeWorkPhone": "work_home_phone",
        "FLIS_ApplicantsRoleisFisheriesOther": "applicant_role_in_fisheries",
        "FLIS_NameofOrganizationCompany": "company_name",
        "FLIS_WeighinStartDate": "tournament_information.weigh_in_start_time",
        "FLIS_ApplyingTelephone": "cell_phone_user",
        "FLIS_EndDateandTime": "tournament_information.end_date_time",
        ...mapCommonCardProperties,
        // will continue when BO issue is resolved

    }
}


export let icmValueTransformation = {
    "APPLICATION_FOR_FISHING_VESSEL": {
        licence_type: (value) => {
            return {"Commercial": "Commercial", "Recreational Fishing": "Recreational", "Access Agreements (Foreign Fishing)": "Foreign"}[value]
        }
    }   
}