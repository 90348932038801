import React from 'react';

function ApplicationHeading(props) {
    return (
        <div style={{textAlign: 'center', backgroundColor:'#00b7fd', padding: '18px', color:'white', fontWeight:'600',fontSize: '28px',lineHeight:'20px'}}>
            <div>{props.title}</div>

        </div>
    );
}

export const ApplicationHeadingWithActions = ({title, actions = []}) => {

    return (
        <div style={{textAlign: 'center', backgroundColor:'#00b7fd', padding: '18px', color:'white', fontWeight:'600',fontSize: '28px',lineHeight:'20px', position: 'relative'}}>
        <div>{title}</div>

        <div style={{position: 'absolute', right: '15px', top: '15px'}}>
        {
            actions.length > 0 && actions.map((action) => {
                return <button className={`${action.className}`} style={{fontWeight: 500}} onClick={() => {
                  action.onClick && action.onClick();  
                }}>{action.title}</button>
            })
        }
        </div>
        
    </div>
    );

}

export default ApplicationHeading;