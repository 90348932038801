import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "../../../assets/styles/form.css";
import HorizontalLine from "../../components/HorLine";
import { ROUTES, PARISH_LIST, ALL_DROPDOWNS } from "../../config/index";
import SimpleNavigator from "../../components/SimpleNavigator";
import { postData } from "../../services/service-call";
import { getCurrentTransaction } from "../../redux/actions/current-transaction";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
// import AlertDialogSlide from "../../components/Modal/Modal";
import { alertService } from "../../_services";


import {
  licenceTypeMapping,
  typeOfTransactions,
  individualYesDocList,
  emailPattern,
  FISHER_LICENCE,
  Break,
  isRequiredIndividual,
  isRequiredCompany,
  telephonePattern,
  formatPhoneNumber,
  getMaxDateForAtleastYear,
  baseLocations,
  BASE_LOCATION_PORT,
  regionParishList,
  regionParishPort,
  fishingAreaLocations,
  BASE_LOCATION_INLAND,
  InlandList,
  regionListForInland,
  FISHER_WORKER_ID,
  fisherWorkerIDTransactionsMapping
} from "../../components/DataSource";
import { FLISContext } from "../../context/FLISContext";
import { FormHookCheckbox, FormHookInput, FormHookRadioInput, FormHookSelect, FormHookTextArea } from "../../components/FormHook/ReactFormHookFields";
import { countries } from "../AuthorizationLicence/AuthorizationDataSource";
import { emailNotValid, telephoneNotValid } from "../../utils/messages";
import GeneralDeclarations from "../GeneralDeclaration";
import { fillInAllTheFields, getPreviewHeading, saveAndContinueLaterText, scrollToTop } from "../../utils/utils";
import SimpleDeclaration from "../SimpleDeclaration";

export const ApplicationForFisherWorkerId = (props) => {

  const {selectedMap, setSelectedMap} = useContext(FLISContext);
  const history = useHistory();
  const buttonSubmitRef = useRef();
 

  const transactionDetails = useSelector((state) => {
    return state.currentTransaction;
  });
  
  useEffect(() => {
    if (transactionDetails.temp_trans_id) {
      // dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
    }
  }, [transactionDetails.temp_trans_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const submitAllDetails = () => {
    history.push("/ftc/transaction-details");
  };

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger
  } = useForm({
    defaultValues: selectedMap,
    mode: 'all'
  });

  React.useImperativeHandle(props.reference, () => {
    return {
      ...(props?.reference?.current || {}),
      trigger: trigger,
    };
  });

  const {errors} = formState;

  let appType = useSelector((state) => {
    return state?.currentTransaction?.appType;
  });


  const onSubmit = async (data) => {

    if (selectedForm === 1) {
      scrollToTop();
      setSelectedForm(2);
      return;
    }
    data.temp_trans_id = props.temp_trans_id;
    history.push(props.next);
  };

  const nextAction = async () => {
    const isValid = await trigger();
    const data = getValues();

    if(selectedForm === 2 && !data?.applicant_signature  && !data?.signature_draw_path){
      alertService.warn('Fill all the mandatory fields');
      return; 
    }


    if(isValid){
      setSelectedMap(data)
    } else {
      alertService.warn(fillInAllTheFields);
    }

    buttonSubmitRef.current.click();
  };

  const previousAction = () => {
    const data = getValues();
    setSelectedMap(data)
    if (selectedForm === 1) {
      history.push(props.previous);
      return;
    } else if (selectedForm === 2) {
      setSelectedForm(1);
    }
    scrollToTop();
  };

  const saveAndContinueLater = async () => {
    const currentData = getValues();
    currentData.temp_trans_id = props.temp_trans_id;
    
    const result = await postData({
      url: `${ROUTES.saveApplication}?app_type=${appType}`,
      body: {
        ...selectedMap,
        temp_trans_id: props.temp_trans_id,
        app_type: appType,
        launchCase: false,
      },
    });

    if (result.statusCode === 200) {
      alertService.success("Application saved successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });

      history.replace("/application-types");
    } else {
      alertService.error("Error occured while saving application", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    }
  };

  const saveYourStore = () => {
    const data = getValues();
    data.temp_trans_id = props.temp_trans_id;
    // localStorage.setItem("ftc_complaints", JSON.stringify(data));
  };


  const [selectedForm , setSelectedForm] = useState(1);

  return (
    <div className="container-fluid " ref={componentRef}>
      <div className="head-text">
        {props.isPreview
          ? getPreviewHeading(appType, props.isFormPDF)
          : selectedForm === 1
          ? "Application Form"
          : "Declaration of Knowledge - Form"}
      </div>

      <div className="form-container">
        <fieldset disabled={props.isDisabled || props.isPreview}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            // onChange={() => saveYourStore()}
            ref={props.reference}
          >
            {selectedForm === 1 && (
              <>
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Applicant Information</div>
                  <HorizontalLine></HorizontalLine>
                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookInput
                      register={register}
                      error={errors?.nfa_registration_number?.message}
                      label="NFA Registration Number"
                      regFieldName="nfa_registration_number"
                      placeholder="NFA Registration Number"
                      isRequired={false}
                      type="text"
                      isDisabled={true}
                    />

                    <Break />

                    <div className="individual-field-margin1">
                      <label className="individual-field-margin mt-2">
                        Name of Applicant(Individuals):
                      </label>

                      <div className="d-flex-wrap sub-form-container-no-border">
                        <FormHookInput
                          register={register}
                          error={errors?.last_name_individual?.message}
                          label="Last"
                          regFieldName="last_name_individual"
                          placeholder="Last"
                          isRequired={isRequiredIndividual(selectedMap)}
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.first_name_individual?.message}
                          label="First"
                          regFieldName="first_name_individual"
                          placeholder="First"
                          isRequired={isRequiredIndividual(selectedMap)}
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.middle_name_individual?.message}
                          label="Middle"
                          regFieldName="middle_name_individual"
                          placeholder="Middle"
                          isRequired={false}
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.alias_individual?.message}
                          label="Alias"
                          regFieldName="alias_individual"
                          placeholder="Alias"
                          isRequired={false}
                        />
                      </div>
                    </div>

                    <Break />

                    <FormHookInput
                      register={register}
                      error={errors?.company_name?.message}
                      label="Name of Applicant(If Company)"
                      regFieldName="company_name"
                      placeholder="Name of Applicant"
                      type="text"
                      isRequired={isRequiredCompany(selectedMap)}
                      toAddOptionalText={false}
                    />

                    <FormHookSelect
                      register={register}
                      error={errors?.country_of_origin?.message}
                      label="Nationality / Country of Origin"
                      regFieldName="country_of_origin"
                      options={countries}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.national_registration_id?.message}
                      label="Nationality Registration ID"
                      regFieldName="national_registration_id"
                      placeholder="Nationality Registration ID"
                      type="text"
                      // isRequired={isRequiredIndividual(selectedMap)}
                      isRequired={false}
                      toAddOptionalText={false}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.taxation_registration_number?.message}
                      label="Taxation Registration Number"
                      regFieldName="taxation_registration_number"
                      placeholder="Taxation Registration Number"
                      type="text"
                      isRequired={false}
                      toAddOptionalText={false}
                    />

                    <Break />

                    <FormHookSelect
                      register={register}
                      error={errors?.country_address?.message}
                      label="Country"
                      regFieldName="country_address"
                      options={countries}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.cell_phone?.message}
                      label="Cell Phone"
                      regFieldName="cell_phone"
                      placeholder="Cell Phone"
                      type="text"
                      validations={{
                        pattern: {
                          value: telephonePattern,
                          message: telephoneNotValid,
                        },
                      }}
                      onChange={formatPhoneNumber}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.work_home_phone?.message}
                      label="Work / Home Phone"
                      regFieldName="work_home_phone"
                      placeholder="Work / Home Phone"
                      type="text"
                      isRequired={false}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.email?.message}
                      label="Email"
                      regFieldName="email"
                      placeholder="Email"
                      type="text"
                      validations={{
                        pattern: {
                          value: emailPattern,
                          message: emailNotValid,
                        },
                      }}
                      isRequired={false}
                    />

                    <Break />

                    <FormHookInput
                      register={register}
                      error={errors?.contact_name?.message}
                      label="Contact"
                      regFieldName="contact_name"
                      placeholder="Contact"
                      type="text"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.date_of_birth_individual?.message}
                      label="Date of Birth (Individual)"
                      regFieldName="date_of_birth_individual"
                      type="date"
                      isRequired={isRequiredIndividual(selectedMap)}
                      maxDate={getMaxDateForAtleastYear(16)}
                    />

                    <FormHookRadioInput
                      label="Gender / Type of Application:"
                      fieldClassName="d-flex-wrap flex-row"
                      isKeyValue={true}
                      options={[
                        {
                          label: "Male",
                          value: "Male",
                        },
                        {
                          label: "Female",
                          value: "Female",
                        },
                        {
                          label: "Business",
                          value: "Business",
                        },
                      ]}
                      register={register}
                      error={errors?.gender_type_of_business?.message}
                      regFieldName={`gender_type_of_business`}
                    />

                    <Break />

                    <FormHookTextArea
                      register={register}
                      error={errors?.address?.message}
                      label="Address"
                      regFieldName="address"
                      placeholder="Address"
                      cols={72}
                    />

                    <Break />

                    <div className="individual-field-margin1">
                      <label className="individual-field-margin mt-2">
                        National Picture Identification{" "}
                        <span style={{ fontSize: "12px" }}>
                          ((for first time applicants and if renewing/replacing
                          NFA ID):)
                        </span>
                      </label>

                      <div className="d-flex-wrap sub-form-container-no-border">
                        <FormHookSelect
                          register={register}
                          error={
                            errors?.type_of_picture_identification?.message
                          }
                          label="Type"
                          regFieldName="type_of_picture_identification"
                          options={individualYesDocList}
                          isDisabled={false}
                          isRequired={
                            isRequiredIndividual(selectedMap) && false
                          }
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.picture_identification_number?.message}
                          label="No."
                          regFieldName="picture_identification_number"
                          placeholder="No."
                          isDisabled={false}
                          isRequired={
                            isRequiredIndividual(selectedMap) && false
                          }
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.picture_expiry_date?.message}
                          label="Expiry Date"
                          regFieldName="picture_expiry_date"
                          type="date"
                          isDisabled={false}
                          isRequired={
                            isRequiredIndividual(selectedMap) && false
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Transaction Details</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-column justify-content-start">
                    <FormHookCheckbox
                      label="Transaction(s) Required"
                      options={[
                        {
                          value: typeOfTransactions[FISHER_WORKER_ID][0],
                          label: fisherWorkerIDTransactionsMapping[typeOfTransactions[FISHER_WORKER_ID][0]]
                        },
                        {
                          value: typeOfTransactions[FISHER_WORKER_ID][1],
                          label: fisherWorkerIDTransactionsMapping[typeOfTransactions[FISHER_WORKER_ID][1]]
                        }
                      ]}
                      register={register}
                      error={errors?.type_of_transaction?.message}
                      regFieldName={`type_of_transaction`}
                      isDisabled={true}
                      isKeyValue={true}
                    />

                    {/* {selectedMap.type_of_transaction === RENEWAL && (
                      <div>
                        <FormHookCheckbox
                          label="Renewal Type"
                          options={RenewalTypeOptionsList}
                          register={register}
                          error={errors?.renewal_type?.message}
                          regFieldName={`renewal_type`}
                          isDisabled={true}
                        />
                      </div>
                    )} */}

                    <FormHookCheckbox
                      label="Licence Type"
                      options={licenceTypeMapping[FISHER_LICENCE] ?? []}
                      register={register}
                      error={errors?.licence_type?.message}
                      regFieldName={`licence_type`}
                      isDisabled={true}
                    />

                    <FormHookCheckbox
                      label="Port Or Inland"
                      options={baseLocations}
                      register={register}
                      error={errors?.port_or_inland?.message}
                      regFieldName={`port_or_inland`}
                      isDisabled={true}
                      isMulti={false}
                    />

                    {selectedMap.port_or_inland === BASE_LOCATION_PORT && (
                      <div>
                        <FormHookCheckbox
                          label="Region/Parish"
                          options={regionParishList}
                          register={register}
                          error={errors?.region?.message}
                          regFieldName={`region`}
                          isDisabled={true}
                          isMulti={false}
                        />

                        <FormHookCheckbox
                          label="Port"
                          options={regionParishPort?.[selectedMap.region]}
                          register={register}
                          error={errors?.port?.message}
                          regFieldName={`port`}
                          isDisabled={true}
                          isMulti={false}
                        />

                        <FormHookCheckbox
                          label="Fishing Area"
                          options={fishingAreaLocations ?? []}
                          register={register}
                          error={errors?.fishing_area?.message}
                          regFieldName={`fishing_area`}
                          isDisabled={true}
                        />
                      </div>
                    )}

                    <Break />

                    {selectedMap.port_or_inland === BASE_LOCATION_INLAND && (
                      <div>
                        <FormHookCheckbox
                          label="Region/Parish"
                          options={regionListForInland}
                          register={register}
                          error={errors?.region?.message}
                          regFieldName={`region`}
                          isDisabled={true}
                          isMulti={false}
                        />

                        <Break />

                        <FormHookCheckbox
                          label="Inland"
                          options={InlandList}
                          register={register}
                          error={errors?.inland?.message}
                          regFieldName={`inland`}
                          isDisabled={true}
                          isMulti={false}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <SimpleDeclaration register={register} errors={errors} />
              </>
            )}

            {(selectedForm === 2 || props.isPreview) && (
              <GeneralDeclarations
                register={register}
                errors={errors}
                watch={watch}
                isDisabled={props.isDisabled || props.isPreview}
                setValue={setValue}
              ></GeneralDeclarations>
            )}

            <div className="d-flex flex-row-reverse">
              <div className="ml-2">
                <button
                  hidden={true}
                  ref={buttonSubmitRef}
                  className="btn btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </fieldset>
        <div hidden={props.isDisabled || props.isPreview}>
          <SimpleNavigator
            next
            middle
            middleValue={saveAndContinueLaterText}
            middleAction={saveAndContinueLater}
            nextAction={nextAction}
            previousAction={previousAction}
            previous
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    temp_trans_id: state.currentTransaction.temp_trans_id,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationForFisherWorkerId);
