export const transactionsUIColumns = [
  "S No.",
  "Submitted On",
  "Trans Number",
  "Applicant Name",
  "NFA Number",
  "Type of Transaction",
  "Category",
  "Status",
  "View",
];

export const checkApplicationStatusColumns = [
  "Date Submitted",
  "Transaction Number",
  "Type of Application",
  "Application Status",
  "View Licences/Permits"
]

export const searchLicenceUIColumns = [
  "NFA Reg. #",
  "Transaction Number",
  "Applicant Name",
  "Type of Licence/Permit",
  "Permit/Licence #",
  "Category",
  "Type of Fish/Species",
  "Port / Inland",
  "Date of Issue",
  "Date of Expiration",
  "Card Issue Date",
  "Card Expiry Date",
  "Licence/Card Certificate"
]