export const ResponsiveTable = ({
  isBordered = true,
  headers = [],
  children,
  className="table-responsive",
  headerClass="",
  tableClass="",
  style={}
}) => {
  return (
    <div style={style} className={className}>
      <table className={`table ${isBordered ? "table-bordered" : ""} ${tableClass}`}>
        <thead className={headerClass}>
          <tr>
            {headers.map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>

        {children}
      </table>
    </div>
  );
};
