import React, { useEffect, useState } from "react";
import AlertDialogSlide from "./Modal/Modal";
import { FormHookInput } from "./FormHook/ReactFormHookFields";
import { useForm } from "react-hook-form";
import { getData } from "../services/service-call";
import { ROUTES } from "../config";
import { alertService } from "../_services";
import { ResponsiveTable } from "./ResponsiveTable";
import {
  checkApplicationStatusColumns,
  transactionColumnWithoutView,
  transactionsUIColumns,
} from "../containers/AdminModule/AdminDataSource";
import {
  getApplicationSpecificDetails,
  getApplicationSpecificDetailsWithMultipleKey,
  getSimpleDateOnlyFormat,
} from "../utils/utils";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ApplicationHeadingWithActions } from "./ApplicationHeading";
import { ICMCaseNameMapping } from "./DataSource";
import { desc } from "../config/application-submission-config";

export const TransactionStatus = () => {

  const [searchDialog, setSearchDialog] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {},
    mode: "all",
  });
  const { errors } = formState;

  const [allTransactions, setAllTransactions] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  useEffect(() => {
    setSearchDialog(true);
  }, [queryParams.get('id')]);

  return (
    <div>
      <AlertDialogSlide
        fullWidth={false}
        open={searchDialog}
        titleStyle={{backgroundColor: 'green', color: 'white', textAlign: 'center', width: '100%'}}
        title={`Application Status`}
        hideCrossIcon={true}
        handleClose={async () => {
          setSearchDialog(false);
        }}
        handleCloseSecondary={() => {
          setSearchDialog(false);
        }}
        content={
          <div>
            <div style={{paddingLeft: '10px', color: '#336699', fontSize: '20px'}}>
            To check your application status, enter the transaction number below
            </div>
            <div>
              <FormHookInput
                parentClass="individual-field"
                register={register}
                error={errors?.trans_number?.message}
                label="Transaction Number"
                regFieldName="trans_number"
                // helpText="Enter transaction number to check"
              />
            </div>

            {/* CTAS */}
            <div className="m-2 d-flex-wrap flex-row justify-content-end">
              <button
                className="btn btn-primary"
                type="button"
                onClick={async () => {
                  const isValid = await trigger();
                  if (!isValid) return;

                  const result = await getData({
                    url: `${ROUTES.getTransaction}${getValues().trans_number}`,
                  });
                  if (result.statusCode === 200) {
                    alertService.success(result.data.msg);
                    setAllTransactions(
                      result.data.transaction ? [result.data.transaction] : []
                    );
                    
                    if(!result.data.transaction)  {
                        alertService.warn("Transaction Number Not Found");
                        return;
                    }
                    setSearchDialog(false);
                    setValue('trans_number', '');
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        }
      ></AlertDialogSlide>

      <ApplicationHeadingWithActions title={"Application Status"} />

      <div style={{ padding: "10px" , marginBottom: '100px'}}></div>
      {allTransactions?.length > 0 && (
        <ResponsiveTable
          style={{paddingLeft: '10%', paddingRight: '10%'}}
          isBordered={true}
          headers={checkApplicationStatusColumns}
          className="table-responsive table-striped "
          headerClass="blue-gradient-bg"
        >
          <tbody style={{ fontWeight: 600, fontSize: '23px' }}>
            {allTransactions.map((trans, index) => {
                console.log(trans.app_type);
              return (
                <tr key={trans.id}>
                  <td>{getSimpleDateOnlyFormat(trans.submitted_on)}</td>
                  <td>{trans.id}</td>
                  <td>{desc[trans.app_type]}</td>
                  <td>
                    <div className={'badge bg-success text-light p-2'} style={{fontSize: '22px'}}>{trans.status}</div>
                  </td>

                  <td>
                 {
                    ["Licence Approved", "Case Closed"].indexOf(trans.status) > 0 &&  <button
                    className="d-flex justify-content-center flex-column btn btn-warning"
                    onClick={async (e) => {
                      history.push("/profile?active=licence-permit-tab");
                    }}
                  >
                    View Licence
                  </button>
                 }

                 {
                    ["Licence Approved", "Case Closed"].indexOf(trans.status) === -1 &&  <>NA</>
                 }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ResponsiveTable>
      )}

      {/* <div className="d-flex flex-row justify-content-around">
        <button
          onClick={() => {
            setSearchDialog(true);
          }}
          className="btn  btn-primary"
        >
          Check Application Status
        </button>

        <button
          onClick={async () => {
            history.push("/profile?active=transactions-tab");
          }}
          className="btn  btn-primary"
        >
          View All Applications
        </button>

        <button
          onClick={async () => {
            history.push("/profile?active=licence-permit-tab");
          }}
          className="btn  btn-primary"
        >
          View All Licences
        </button>
      </div> */}
    </div>
  );
};
