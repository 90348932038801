import React, { useContext, useEffect , forwardRef} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FLISContext } from "../context/FLISContext";
import { FormHookFileUpload, FormHookInput, FormHookSelect, FormHookTextArea } from "./FormHook/ReactFormHookFields";
import { FormHookFileUploadHandler } from "./FormHook/FormHookDocument";
import { emailPattern, formatPhoneNumber, power_units, telephonePattern } from "./DataSource";
import { emailNotValid, telephoneNotValid } from "../utils/messages";

export const EngineDetails = ({formState,control, register, onSubmit,values, setValue, reset, watch , isDisabled = false,  ...props  }) => {

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'fishing_vessel_details.engine_details',
    });

    const {errors} = formState;

    const engineDetails = watch('fishing_vessel_details.engine_details');

    return (
      <div className="form-container">
        <fieldset>
          <form onSubmit={onSubmit}>
            {fields.map((group, groupIndex) => {
              const knownEngineNumber = watch(
                `fishing_vessel_details.engine_details[${groupIndex}].engine_serial_number_available`
              );
              return (
                <div
                  key={group.id}
                  className="shadow p-3 mb-3 bg-white rounded"
                >
                  <div className="d-flex-wrap flex-row justify-content-start">
                    <div className="d-flex-wrap sub-form-container-no-border">
                      <FormHookInput
                        register={register}
                        error={
                          errors?.fishing_vessel_details?.engine_details?.[
                            groupIndex
                          ]?.make_manufacturer?.message
                        }
                        label="Make / Manufacturer"
                        regFieldName={`fishing_vessel_details.engine_details[${groupIndex}].make_manufacturer`}
                        placeholder="Make / Manufacturer"
                        isDisabled={isDisabled}
                      />

                      <FormHookSelect
                        label="Engine Power Unit"
                        error={
                          errors?.fishing_vessel_details?.engine_details?.[
                            groupIndex
                          ]?.engine_power_unit?.message
                        }
                        register={register}
                        regFieldName={`fishing_vessel_details.engine_details[${groupIndex}].engine_power_unit`}
                        options={power_units}
                        isKeyValue={true}
                        isDisabled={isDisabled}
                      />

                      <FormHookInput
                        register={register}
                        error={
                          errors?.fishing_vessel_details?.engine_details?.[
                            groupIndex
                          ]?.horse_power?.message
                        }
                        label="Engine Horse Power"
                        regFieldName={`fishing_vessel_details.engine_details[${groupIndex}].horse_power`}
                        placeholder="Engine Horse Power"
                        type="number"
                        isDisabled={isDisabled}
                      />

                      <FormHookSelect
                        label="Do you know Engine Serial Number"
                        error={
                          errors?.fishing_vessel_details?.engine_details?.[
                            groupIndex
                          ]?.engine_serial_number_available?.message
                        }
                        register={register}
                        regFieldName={`fishing_vessel_details.engine_details[${groupIndex}].engine_serial_number_available`}
                        options={["Known", "Unknown"]}
                        isDisabled={isDisabled}
                      />

                      <FormHookInput
                        register={register}
                        error={
                          errors?.fishing_vessel_details?.engine_details?.[
                            groupIndex
                          ]?.engine_serial_number?.message
                        }
                        label="Engine Serial Number"
                        regFieldName={`fishing_vessel_details.engine_details[${groupIndex}].engine_serial_number`}
                        placeholder="Engine Serial Number"
                        isDisabled={
                          knownEngineNumber === "Unknown" || isDisabled
                        }
                        isRequired={
                          knownEngineNumber !== "Unknown"
                            ? "This field is required"
                            : false
                        }
                        toAddOptionalText={false}
                      />

                      <div>
                        {engineDetails?.length > 1 && !isDisabled && (
                          <button
                            style={{ marginTop: "25px" }}
                            className="btn btn-warning"
                            onClick={() => {
                              remove(groupIndex);
                            }}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </form>
        </fieldset>

        {!isDisabled && (
          <div className="d-flex flex-row">
            <button
              type="button"
              onClick={() => {
                append({ id: Math.random() });
              }}
              className="btn btn-primary btn-sm"
            >
              Add Another Engine
            </button>
          </div>
        )}
      </div>
    );
};
